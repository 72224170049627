<template>
  <div class="text-white" :class="transparent ? 'bg-transparent' : 'bg-sky-blue-900'">
    <form
      :class="{ 'standard-spacing': withSpacing }"
      class="md:flex items-center justify-between py-16 md:py-8"
      @submit.prevent="subscribe">
      <div class="text-xl lg:text-3xl font-decorative w-auto lg:w-1/2">Join our Hoap community</div>
      <div v-if="!loading && errorMessage" class="text-copper-700">
        {{ errorMessage }}
      </div>
      <div v-if="loading" class="lg:w-1/2 flex items-center justify-center">
        <HoapIcon icon="Loading" class="w-12 h-12" />
      </div>
      <div v-else-if="success" class="text-lime-600">Successfully subscribed!</div>
      <div v-else class="w-full lg:w-1/2 md:flex items-center mt-2 md:mt-0">
        <HoapInput
          v-model="email"
          type="email"
          :disabled="loading"
          required
          placeholder="Email"
          class="w-full md:w-auto mb-3 md:mb-0 flex-1 text-sm h-full lg:text-base" />
        <HoapSelect
          v-model="role"
          placeholder="Role"
          required
          aria-required="true"
          :disabled="loading"
          :options="roleOptions"
          class="w-full md:w-auto mb-3 md:mb-0 mt-4 md:mt-0 md:ml-4 text-sm h-full lg:text-base" />
        <div class="flex justify-end">
          <HoapButton label="Subscribe" inverted :loading="loading" class="mt-4 md:mt-0 md:ml-4" type="submit" />
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import Axios from 'axios'
  import { HoapButton, HoapInput, HoapSelect, HoapIcon } from '..'

  defineProps({
    transparent: Boolean,
    withSpacing: { type: Boolean, default: true },
  })

  const roleOptions = [
    { value: 'leader', label: 'Leader' },
    { value: 'talent', label: 'Talent' },
  ]

  const axios = Axios.create()

  const email = ref('')
  const role = ref('')
  const loading = ref(false)
  const success = ref(false)
  const errorMessage = ref('')

  const subscribe = async () => {
    loading.value = true
    success.value = false
    errorMessage.value = ''

    if (!role.value) {
      loading.value = false
      errorMessage.value = 'Please select a role.'

      return
    }

    try {
      await axios.post('/api/subscribe', {
        email: email.value,
        role: role.value,
      })

      success.value = true
    } catch (e: unknown) {
      errorMessage.value = `${e.response.statusText}`
    } finally {
      loading.value = false
    }
  }
</script>
